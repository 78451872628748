<template>
  <v-card flat title>
    <channel-messages :channelId="channelId" :toolbar="true" @channelDeleted="onChannelDeleted"/>
  </v-card>
</template>

<script>
import ChannelMessages from '@/messaging/components/channel/messages.vue'

export default {
  components: {
    ChannelMessages
  },
  data() {
    return {

    }
  },
  computed: {
    channelId() {
      return this.$route.params.channelId
    }
  },
  methods: {
    onChannelDeleted() {
      this.$router.push({ name: 'messaging-home' })
    }
  }
}
</script>
